import { Box } from "@mui/material";
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";

import { BarChartLine, GlobeAmericas, Link45deg, LightningCharge, Bullseye, People } from "react-bootstrap-icons";

import Sphere from "../components/Sphere";

import { useNavigate } from "react-router-dom";

import "../assets/style/Main.css";

const Main = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <section>
          <Grid sx={{ alignItems: "center", justifyContent: "center" }} direction={"row"} container>
            <Grid size={{ xs: 12, md: 6 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <p className="text" style={{ margin: 0 }}>
                DEMOCRATIZING INFLUENCE
              </p>
              <h1 className="heading">
                <span className="underline">Revolutionize</span> Your <br />
                <b className="font-serif">Digital</b> Presence
              </h1>
              <p className="text">Reach is a freelance marketing platform changing the way brands and consumers interact. Create large-scale social media ambassador campaigns made up of thousands of your most loyal supporters.</p>
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <button className="btn btn-secondary" onClick={() => navigate("/about")}>
                  About us
                </button>
                <button className="btn btn-primary" onClick={() => navigate("/waitlist")}>
                  Join the Waitlist
                </button>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Sphere />
            </Grid>
          </Grid>
        </section>
      </Container>

      <Container>
        <section>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h2 className="sub-heading font-serif">What is Reach?</h2>
            </Box>

            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Link45deg className="icon" />
                <h3 className="sub-title">Bridging Connections</h3>
                <p className="text">Seamlessly connect brands with real voices and real people. Reach fosters meaningful collaborations between companies and their communities, driving genuine engagement and measurable results.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <BarChartLine className="icon" />
                <h3 className="sub-title">Democratizing Influence</h3>
                <p className="text">
                  Unlock the power of your customers to amplify your brand’s voice. With Reach, companies can recruit, manage, and monitor thousands of customers as brand ambassadors, enabling scalable, authentic marketing campaigns that resonate.
                </p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <LightningCharge className="icon" />
                <h3 className="sub-title">Empowering Individuals</h3>
                <p className="text">
                  Turn your passion for your favorite brands into a rewarding opportunity. Reach connects social media users with companies they love, offering compensation for creating and sharing authentic content. Join the waitlist and start
                  making an impact today!
                </p>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Container>

      <section className="radial-background">
        <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center", gap: "16px" }}>
          <h2 className="sub-heading font-serif gray-100">Coming Soon...</h2>

          <p className="text ">
            We’re launching soon and want you to be part of it! Join our waitlist to access exclusive campaigns and earn $3–$10 for sharing content on your Instagram stories. Sign up today and help shape the future of social media marketing!
          </p>
          <button className="btn btn-primary" onClick={() => navigate("/waitlist")}>
            Join the Waitlist Now!
          </button>
        </Container>
      </section>

      <Container>
        <section>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "64px" }}>
            <h2 className="sub-heading font-serif">Our Mission & Purpose</h2>
          </Box>
          <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Bullseye className="icon" />
              <h3 className="sub-title">Mission</h3>
              <p className="text">To revolutionize marketing by transforming everyday social media users into empowered brand advocates, creating authentic connections between companies and their communities at scale.</p>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <People className="icon" />
              <h3 className="sub-title">What We’re About</h3>
              <p className="text">Reach is about redefining influence. We believe in the power of real voices, real connections, and real rewards, enabling brands to grow through the genuine enthusiasm of their supporters.</p>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <GlobeAmericas className="icon" />
              <h3 className="sub-title">Purpose</h3>
              <p className="text">To democratize the world of influence, providing opportunities for individuals to share their passions and be rewarded while helping companies achieve impactful, community-driven growth.</p>
            </Grid>
          </Grid>
        </section>
      </Container>
    </>
  );
};

export default Main;
