import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";

import { Backpack2, Calendar2, GraphUpArrow } from "react-bootstrap-icons";
import "../assets/style/Form.css";

import { db } from "../config/Firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Waitlist = () => {
  const navigate = useNavigate();
  const [waitlistStatus, setWaitlistStatus] = useState("");

  const checkWaitlist = async (email) => {
    try {
      const docRef = doc(db, "waitlist", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return true; // User is already on the waitlist
      }

      return false; // User is not on the waitlist
    } catch (error) {
      console.error("Error checking waitlist:", error);
      return false;
    }
  };

  const addToWaitlist = async (values) => {
    try {
      const waitlistEntry = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,

        company: values.company || null, // Add company name if provided
        timestamp: new Date(),
      };
      await setDoc(doc(db, "waitlist", values.email), waitlistEntry);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      console.error("Error adding to waitlist:", error);
    }
  };

  return (
    <>
      <Container>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            company: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = "Invalid email address";
            }
            if (!values.firstName) {
              errors.firstName = "Required";
            }
            if (!values.lastName) {
              errors.lastName = "Required";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setWaitlistStatus("");
            const isAlreadyOnWaitlist = await checkWaitlist(values.email);

            if (isAlreadyOnWaitlist) {
              setWaitlistStatus("You are already on the waitlist.");
            } else {
              await addToWaitlist(values);
              setWaitlistStatus("You've been added to the waitlist. Thank you!");
            }
            setSubmitting(false);
            resetForm();
          }}>
          {({ values, errors, handleSubmit, isSubmitting, handleChange, handleBlur }) => (
            <section style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "16px",
                  maxWidth: "600px",
                  width: "100%",
                }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <h1 className="heading font-serif">Sign Up</h1>
                </Box>

                {/* First Name and Last Name */}
                <Box sx={{ display: "flex", gap: "16px", width: "100%" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <label>First Name *</label>
                    <input required type="text" id="firstName" name="firstName" placeholder="John" value={values.firstName} onChange={handleChange} onBlur={handleBlur} style={{ width: "100%" }} />
                    {errors.firstName && <span style={{ color: "red" }}>{errors.firstName}</span>}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <label>Last Name *</label>
                    <input type="text" id="lastName" name="lastName" placeholder="Smith" value={values.lastName} onChange={handleChange} onBlur={handleBlur} style={{ width: "100%" }} />
                    {errors.lastName && <span style={{ color: "red" }}>{errors.lastName}</span>}
                  </Box>
                </Box>

                {/* Email and Company */}
                <Box sx={{ display: "flex", gap: "16px", width: "100%" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <label>Email *</label>
                    <input type="email" id="email" name="email" placeholder="john@example.com" value={values.email} onChange={handleChange} onBlur={handleBlur} style={{ width: "100%" }} />
                    {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <label>Company</label>
                    <input type="text" id="company" name="company" placeholder="Reach Social" value={values.company} onChange={handleChange} onBlur={handleBlur} style={{ width: "100%" }} />
                  </Box>
                </Box>

                <button className="btn btn-primary width-100" type="submit" disabled={isSubmitting}>
                  Submit
                </button>

                {waitlistStatus && <p className="text">{waitlistStatus}</p>}
              </form>
            </section>
          )}
        </Formik>
      </Container>

      <Container>
        <section>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h2 className="sub-heading font-serif">Join the Waitlist</h2>
            </Box>

            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Calendar2 className="icon" />
                <h3 className="sub-title">Early Access</h3>
                <p className="text">Be among the first to experience our platform and gain exclusive early access to powerful tools.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <GraphUpArrow className="icon" />
                <h3 className="sub-title">Growth Opportunities</h3>
                <p className="text">Unlock unique opportunities to grow your brand and connect with audiences in meaningful ways.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Backpack2 className="icon" />
                <h3 className="sub-title">Exclusive Perks</h3>
                <p className="text">Receive insider tips, resources, and updates that will set you up for success in your campaigns.</p>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Container>
    </>
  );
};

export default Waitlist;
