import { Box } from "@mui/material";
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { Briefcase, CashCoin, FolderPlus, Gem, Palette, PersonPlus, Stars } from "react-bootstrap-icons";

const Individuals = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <section>
          <Grid sx={{ alignItems: "center", justifyContent: "center" }} direction={"row"} container>
            <Grid size={{ xs: 12 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <p className="text" style={{ margin: 0 }}>
                AUTHENTIC CONTENT, REAL REWARDS
              </p>
              <h1 className="heading">
                <span className="underline">Unlock</span> Authentic <span className="font-serif">Opportunities</span> and Build <span className="font-serif">Rewarding</span> <span className="underline">Partnerships</span>
              </h1>
              <p className="text">Join a platform that values your creativity and voice. Collaborate with top brands, share authentic content, and earn real rewards while building meaningful partnerships.</p>
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <button className="btn btn-secondary" onClick={() => navigate("/about")}>
                  About
                </button>
                <button className="btn btn-primary" onClick={() => navigate("/waitlist")}>
                  Join the Waitlist
                </button>
              </Box>
            </Grid>
          </Grid>
        </section>
      </Container>

      <section className="radial-background">
        <Container>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h2 className="sub-heading font-serif gray-100">How it Works for Individuals</h2>
            </Box>

            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <PersonPlus className="icon gray-100" />
                <h3 className="sub-title gray-100">Create an Account</h3>
                <p className="text ">Create an account and link your social media handles. Add your interests and favorite brands, build out your profile, and search for campaigns!</p>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Briefcase className="icon gray-100" />
                <h3 className="sub-title gray-100">Optimize Profile</h3>
                <p className="text ">Automatically join, apply, or be invited to various campaigns hosted by your favorite companies. You will know which campaigns you are eligible for, or get invited to.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <FolderPlus className="icon gray-100" />
                <h3 className="sub-title gray-100">Join Campaigns</h3>
                <p className="text ">By participating in campaigns and meeting the requirements, you earn cash and other rewards. Incentives are customizable on the company's behalf.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Gem className="icon gray-100" />
                <h3 className="sub-title gray-100">Earn Rewards</h3>
                <p className="text ">By participating in campaigns and meeting the requirements, you earn cash and other rewards. Incentives are customizable on the company's behalf.</p>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </section>

      <Container>
        <section>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h2 className="sub-heading font-serif">Why Join Reach?</h2>
            </Box>

            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <CashCoin className="icon" />
                <h3 className="sub-title">Earn While You Create</h3>
                <p className="text">Get rewarded for sharing content you’re passionate about. Reach offers real compensation for participating in campaigns and supporting the brands you love.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Stars className="icon" />
                <h3 className="sub-title">Collaborate with Top Brands</h3>
                <p className="text">Work with well-known companies and exciting startups to create content that matters. Your voice helps shape the future of authentic marketing.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Palette className="icon" />
                <h3 className="sub-title">Showcase Your Creativity</h3>
                <p className="text">Express your unique style and creativity through fun and engaging campaigns. Reach lets you make an impact while staying true to yourself.</p>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Container>
    </>
  );
};

export default Individuals;
