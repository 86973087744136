import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: window._env_ ? window._env_.REACT_APP_FIREBASE_API_KEY : process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: window._env_ ? window._env_.REACT_APP_FIREBASE_AUTH_DOMAIN : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: window._env_ ? window._env_.REACT_APP_FIREBASE_DATABASE_URL : process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: window._env_ ? window._env_.REACT_APP_FIREBASE_PROJECT_ID : process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: window._env_ ? window._env_.REACT_APP_FIREBASE_STORAGE_BUCKET : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window._env_ ? window._env_.REACT_APP_FIREBASE_MESSAGING_SENDER_ID : process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: window._env_ ? window._env_.REACT_APP_FIREBASE_APP_ID : process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: window._env_ ? window._env_.REACT_APP_FIREBASE_MEASUREMENT_ID : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export { db };