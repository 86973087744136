import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import { db } from "../config/Firebase";
import { doc, setDoc } from "firebase/firestore";

const Contact = () => {
  const navigate = useNavigate();
  const [messageStatus, setMessageStatus] = useState("");

  const sendMessage = async (values) => {
    try {
      const contactEntry = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        message: values.message,
        timestamp: new Date(),
      };
      await setDoc(doc(db, "messages", values.email), contactEntry);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <>
      <Container>
        <section>
          <Grid sx={{ alignItems: "center", justifyContent: "center" }} direction={"row"} container>
            <Grid size={{ xs: 12 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <p className="text" style={{ margin: 0 }}>
                WE'D LOVE TO HEAR FROM YOU
              </p>
              <h1 className="heading">
                <span className="underline">Have a Question?</span> <span className="font-serif">Share Your Thoughts.</span>
              </h1>
              <p className="text">
                Got a question, feedback, or an idea to share? We’re here to listen and respond promptly—drop us a message, and we’ll get back to you as soon as we can! Your thoughts are important to us, and we look forward to hearing from you.
              </p>
            </Grid>
          </Grid>
        </section>
      </Container>

      <Container>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            message: "",
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = "Invalid email address";
            }
            if (!values.firstName) {
              errors.firstName = "Required";
            }
            if (!values.lastName) {
              errors.lastName = "Required";
            }
            if (!values.lastName) {
              errors.message = "Required";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setMessageStatus("");

            await sendMessage(values);
            setMessageStatus("Your message has been sent. Thank you!");

            setSubmitting(false);
            resetForm();
          }}>
          {({ values, errors, handleSubmit, isSubmitting, handleChange, handleBlur }) => (
            <section style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "16px",
                  maxWidth: "600px",
                  width: "100%",
                }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <h2 className="sub-heading font-serif">Contact</h2>
                </Box>

                {/* First Name and Last Name */}
                <Box sx={{ display: "flex", gap: "16px", width: "100%" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <label>First Name *</label>
                    <input required type="text" id="firstName" name="firstName" placeholder="John" value={values.firstName} onChange={handleChange} onBlur={handleBlur} style={{ width: "100%" }} />
                    {errors.firstName && <span style={{ color: "red" }}>{errors.firstName}</span>}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <label>Last Name *</label>
                    <input type="text" id="lastName" name="lastName" placeholder="Smith" value={values.lastName} onChange={handleChange} onBlur={handleBlur} style={{ width: "100%" }} />
                    {errors.lastName && <span style={{ color: "red" }}>{errors.lastName}</span>}
                  </Box>
                </Box>

                {/* Email */}
                <Box sx={{ display: "flex", gap: "16px", width: "100%" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <label>Email *</label>
                    <input type="email" id="email" name="email" placeholder="email@example.com" value={values.email} onChange={handleChange} onBlur={handleBlur} style={{ width: "100%" }} />
                    {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", gap: "16px", width: "100%" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <label>Message</label>
                    <textarea
                      rows={4}
                      type="text"
                      id="message"
                      name="message"
                      placeholder="What's on your mind?"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        width: "100%",
                        maxWidth: "600px",
                        resize: "vertical",
                      }}
                    />
                  </Box>
                </Box>

                <button className="btn btn-primary width-100" type="submit" disabled={isSubmitting}>
                  Submit
                </button>

                {messageStatus && <p className="text">{messageStatus}</p>}
              </form>
            </section>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Contact;
