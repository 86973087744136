import { Box } from "@mui/material";
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { Camera, ClipboardData, ClipboardPlus, FileEarmarkArrowUp, Pencil, PersonVideo3, SendCheck } from "react-bootstrap-icons";

const Companies = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <section>
          <Grid sx={{ alignItems: "center", justifyContent: "center" }} direction={"row"} container>
            <Grid size={{ xs: 12 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <p className="text" style={{ margin: 0 }}>
                ANALYZE MEANINGFUL DATA
              </p>
              <h1 className="heading">
                <span className="underline">Empower</span> Your <b className="font-serif">Brand</b> with{" "}
                <span className="underline">
                  Authentic <br />
                  Connections
                </span>{" "}
                and <b className="font-serif">Data-Driven</b> Analytics
              </h1>
              <p className="text">
                Brands can create an account, set up campaigns with a budget and target demographics, and launch them to engage with customers in producing content. Then easily track performance through detailed analytics as users submit their post’s
                stats.
              </p>
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <button className="btn btn-secondary" onClick={() => navigate("/about")}>
                  About
                </button>
                <button className="btn btn-primary" onClick={() => navigate("/waitlist")}>
                  Join the Waitlist
                </button>
              </Box>
            </Grid>
          </Grid>
        </section>
      </Container>

      <section className="radial-background">
        <Container>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h2 className="sub-heading font-serif gray-100">How it Works for Companies</h2>
            </Box>

            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Pencil className="icon gray-100" />
                <h3 className="sub-title gray-100">Create an Account</h3>
                <p className="text ">Sign up, define your brand's interests, and specify your target demographics to get started seamlessly.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <ClipboardPlus className="icon gray-100" />
                <h3 className="sub-title gray-100">Design Campaigns</h3>
                <p className="text ">Set a budget and define participation options to align your campaign with your goals. Choose from various options to attract the right users while maintaining and fostering meaningful engagement.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <SendCheck className="icon gray-100" />
                <h3 className="sub-title gray-100">Deployment</h3>
                <p className="text ">Launch your campaign and empower your customers to create valuable content. Watch as they engage with your brand and generate authentic content that drives results.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <ClipboardData className="icon gray-100" />
                <h3 className="sub-title gray-100">Track Results</h3>
                <p className="text ">Track your campaign’s performance and analytics as each user submits the stats of their posts. Allowing you to measure engagement and make data-driven decisions for future campaigns.</p>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </section>

      <Container>
        <section>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h2 className="sub-heading font-serif">Campaign Types</h2>
            </Box>

            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Camera className="icon" />
                <h3 className="sub-title">Story Posts</h3>
                <p className="text">Share casual photos/videos of your favorite products on your Instagram, Snapchat, and TikTok stories.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <FileEarmarkArrowUp className="icon" />
                <h3 className="sub-title">Provided Content</h3>
                <p className="text">Companies can supply content for users to share, making it easier to promote the brand. This ensures consistent messaging while empowering users to engage with pre-approved materials.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <PersonVideo3 className="icon" />
                <h3 className="sub-title">User Generated Content</h3>
                <p className="text">Share genuine reviews of your favorite products on the platform of your choice. Your honest feedback helps others make informed decisions and builds trust in the brand.</p>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Container>
    </>
  );
};

export default Companies;
