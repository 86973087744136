import React, { useEffect, useRef } from "react";
import "../assets/style/Sphere.css";

const Sphere = () => {
  const sphereRef = useRef(null);

  useEffect(() => {
    const sphere = sphereRef.current;
    const totalDots = 100; // Number of dots
    const radius = 200; // Radius of the sphere

    for (let i = 0; i < totalDots; i++) {
      const dot = document.createElement("div");
      dot.className = "dot";

      // Calculate spherical coordinates
      const phi = Math.acos(-1 + (2 * i) / totalDots); // Angle from top to bottom
      const theta = Math.PI * (1 + Math.sqrt(5)) * i; // Angle around the sphere

      const x = radius * Math.sin(phi) * Math.cos(theta);
      const y = radius * Math.sin(phi) * Math.sin(theta);
      const z = radius * Math.cos(phi);

      dot.style.transform = `translate3d(${x}px, ${y}px, ${z}px)`;
      sphere.appendChild(dot);
    }
  }, []);

  return (
    <>
      <div className="sphere-container">
        <div className="sphere" ref={sphereRef}></div>
      </div>
    </>
  );
};

export default Sphere;
