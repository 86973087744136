import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";

const NavbarWrapper = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem("darkMode") === "true");
  const [isDarkModeButtonDisabled, setIsDarkModeButtonDisabled] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/privacy-policy" || location.pathname === "/terms-conditions") {
      setIsDarkMode(false);
      setIsDarkModeButtonDisabled(true);
      localStorage.setItem("darkMode", false);
    } else {
      setIsDarkModeButtonDisabled(false);
    }
  }, [location]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark-mode");
    } else {
      document.documentElement.classList.remove("dark-mode");
    }
    localStorage.setItem("darkMode", isDarkMode);
  }, [isDarkMode]);

  return (
    <Navbar
      isDarkMode={isDarkMode}
      setIsDarkMode={setIsDarkMode}
      isDarkModeButtonDisabled={isDarkModeButtonDisabled}
    />
  );
};

export default NavbarWrapper;
