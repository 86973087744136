import version from "../config/Version.js";

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Logo from "../assets/images/Logo.js";
import Reach from "../assets/images/Reach.js";
import LogoLight from "../assets/images/Logo-Light";
import ReachLight from "../assets/images/Reach-Light.js";
import { Box, Drawer } from "@mui/material";
import Container from "@mui/material/Container";
import { Buildings, DoorOpen, FileEarmarkText, List, PersonRaisedHand, PersonVideo, ShieldCheck, X } from "react-bootstrap-icons";
import "../assets/style/Navbar.css";
import DarkModeButton from "./DarkModeButton.js";

const Navbar = ({ isDarkMode, setIsDarkMode, isDarkModeButtonDisabled }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/privacy-policy") {
      setIsDarkMode(false); // Ensure dark mode is off
    }
  }, [location, setIsDarkMode]);

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <>
      <Box id="navbar-top-line"></Box>
      <Container>
        <Drawer open={open} onClick={() => toggleDrawer(false)}>
          <Box id="drawer" sx={{ padding: "8px", width: "300px" }}>
            <Box className="content">
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: "16px" }}>
                <DarkModeButton isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} disabled={isDarkModeButtonDisabled} />
                <a href="/" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  {isDarkMode ? (
                    <>
                      <LogoLight style={{ width: "28px", height: "28px" }} />
                      <ReachLight style={{ height: "18px" }} />
                    </>
                  ) : (
                    <>
                      <Logo style={{ width: "28px", height: "28px" }} />
                      <Reach style={{ height: "18px" }} />
                    </>
                  )}
                </a>

                <button id="menu-icon-close" onClick={() => toggleDrawer(false)}>
                  <X style={{ width: "36px", height: "36px" }} />
                </button>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <p className="drawer-text">
                    <b>About</b>
                  </p>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "8px" }}>
                    <button className="btn-tertiary width-100" onClick={() => navigate("/about")}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        {isDarkMode ? (
                          <>
                            <Logo style={{ width: "28px", height: "28px" }} />
                          </>
                        ) : (
                          <>
                            <LogoLight style={{ width: "28px", height: "28px" }} />
                          </>
                        )}
                        <p className="drawer-text">Reach</p>
                      </Box>
                    </button>

                    <button className="btn-tertiary width-100" onClick={() => navigate("/about/individuals")}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <PersonVideo style={{ width: "28px", height: "28px" }} />
                        <p className="drawer-text ">Individuals</p>
                      </Box>
                    </button>
                    <button className="btn-tertiary width-100" onClick={() => navigate("/about/companies")}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <Buildings style={{ width: "28px", height: "28px" }} />
                        <p className="drawer-text">Companies</p>
                      </Box>
                    </button>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <p className="drawer-text">
                    <b>Join</b>
                  </p>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "8px" }}>
                    <button className="btn-tertiary width-100" onClick={() => navigate("/waitlist")}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <DoorOpen style={{ width: "28px", height: "28px" }} />
                        <p className="drawer-text ">Waitlist</p>
                      </Box>
                    </button>
                    <button className="btn-tertiary width-100" onClick={() => navigate("/contact")}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <PersonRaisedHand style={{ width: "28px", height: "28px" }} />
                        <p className="drawer-text">Contact</p>
                      </Box>
                    </button>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <p className="drawer-text">
                    <b>Legal</b>
                  </p>
                  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "8px" }}>
                    <button className="btn-tertiary width-100" onClick={() => navigate("/privacy-policy")}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <ShieldCheck style={{ width: "28px", height: "28px" }} />
                        <p className="drawer-text ">Privacy Policy</p>
                      </Box>
                    </button>
                    <button className="btn-tertiary width-100" onClick={() => navigate("/terms-conditions")}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <FileEarmarkText style={{ width: "28px", height: "28px" }} />
                        <p className="drawer-text">Terms & Conditions</p>
                      </Box>
                    </button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <p className="text">&copy; PartTime LLC. All rights reserved.</p>
            <p className="text">v{version}</p>
          </Box>
        </Drawer>

        <Box id="navbar">
          <Box>
            <a href="/" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {isDarkMode ? (
                <>
                  <LogoLight style={{ width: "28px", height: "28px" }} />
                  <ReachLight style={{ height: "18px" }} />
                </>
              ) : (
                <>
                  <Logo style={{ width: "28px", height: "28px" }} />
                  <Reach style={{ height: "18px" }} />
                </>
              )}
            </a>
          </Box>
          <Box className="nav-item">
            <a className="nav-link" href="/about">
              About
            </a>
            <a className="nav-link" href="/about/individuals">
              Individuals
            </a>
            <a className="nav-link" href="/about/companies">
              Companies
            </a>
          </Box>
          <Box className="nav-item">
            <button className="btn btn-primary" onClick={() => navigate("/waitlist")}>
              Sign up
            </button>
            <DarkModeButton isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} disabled={isDarkModeButtonDisabled} />
          </Box>

          <button id="menu-icon" onClick={() => toggleDrawer(true)}>
            <List style={{ width: "36px", height: "36px" }} />
          </button>
        </Box>
      </Container>
    </>
  );
};

export default Navbar;
