import { Moon, Sun } from "react-bootstrap-icons";

function DarkModeButton({ isDarkMode, setIsDarkMode, disabled }) {
  const toggleDarkMode = (event) => {
    event.preventDefault();
    if (!disabled) {
      setIsDarkMode((prevMode) => !prevMode);
    }
  };

  return (
    <div>
      <button className="btn-dark-mode" onClick={toggleDarkMode} disabled={disabled} style={{ cursor: disabled ? "not-allowed" : "pointer", opacity: disabled ? 0.5 : 1 }}>
        {isDarkMode ? <Moon style={{ height: "24px", width: "24px" }} /> : <Sun style={{ height: "24px", width: "24px" }} />}
      </button>
    </div>
  );
}

export default DarkModeButton;
