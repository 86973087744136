import { Box } from "@mui/material";
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { BarChartSteps, ChatLeftText, Globe2, Lightbulb, Megaphone, PersonWorkspace, RocketTakeoff } from "react-bootstrap-icons";

const About = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <section>
          <Grid sx={{ alignItems: "center", justifyContent: "center" }} direction={"row"} container>
            <Grid size={{ xs: 12,  }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <p className="text" style={{ margin: 0 }}>
                AUTHENTIC CONNECTIONS MADE SIMPLE
              </p>
              <h1 className="heading">
                Where <b className="font-serif">Brands</b> and  <b className="font-serif">People</b> <span className="underline">Connect</span>
              </h1>
              <p className="text">Reach bridges the gap between brands and individuals, enabling genuine collaborations that benefit both. We’re here to make marketing personal, rewarding, and impactful—one connection at a time.</p>
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <button className="btn btn-secondary" onClick={() => navigate("/about/individuals")}>
                  Individuals
                </button>
                <button className="btn btn-primary" onClick={() => navigate("/about/companies")}>
                  Companies
                </button>
              </Box>
            </Grid>
          </Grid>
        </section>
      </Container>

      <section className="radial-background">
        <Container>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h2 className="sub-heading font-serif gray-100">The Reach Advantage</h2>
            </Box>

            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Megaphone className="icon gray-100" />
                <h3 className="sub-title gray-100">Bridging Connections</h3>
                <p className="text ">Seamlessly connect brands with real voices and real people. Reach fosters meaningful collaborations between companies and their communities, driving genuine engagement and measurable results.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Globe2 className="icon gray-100" />
                <h3 className="sub-title gray-100">Expand Your Reach</h3>
                <p className="text ">Connect with a diverse network of creators and customers across multiple platforms. Reach helps your brand tap into new audiences and grow your presence like never before.</p>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <BarChartSteps className="icon gray-100" />
                <h3 className="sub-title gray-100">Empowering Individuals</h3>
                <p className="text ">Turn your passion for brands into rewards. Connect with companies, share authentic content, and start making an impact today!</p>
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 3 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <ChatLeftText className="icon gray-100" />
                <h3 className="sub-title gray-100">Build Relations</h3>
                <p className="text ">Harness the power of your customers to amplify your brand. Recruit and manage brand ambassadors to create scalable, authentic marketing that resonates.</p>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </section>

      <Container>
        <section>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h2 className="sub-heading font-serif">The Journey Behind Reach</h2>
            </Box>

            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Lightbulb className="icon" />
                <h3 className="sub-title">The Idea</h3>
                <p className="text">
                  Reach began with a simple question: What if anyone could amplify the brands they love while earning rewards? This idea sparked a mission to redefine influence by creating a platform that connects brands with real voices in
                  meaningful ways.
                </p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <PersonWorkspace className="icon" />
                <h3 className="sub-title">The Vision</h3>
                <p className="text">
                  We envisioned a world where authentic connections between brands and individuals drive marketing success. Our goal was to democratize influence, making it accessible to everyone, from everyday users to passionate advocates.
                </p>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <RocketTakeoff className="icon" />
                <h3 className="sub-title">The Journey Ahead</h3>
                <p className="text">
                  Our story doesn’t end here—it’s just beginning. With your support, we’re building a future where marketing is personal, impactful, and community-driven. Together, we can shape the way brands and people connect for years to come.
                </p>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Container>
    </>
  );
};

export default About;
