import React from "react";

const LogoLight = ({ style }) => {
  return (
    <svg style={style} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="5" fill="#ffffff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8561 16.0565C20.3109 15.0701 20.5646 13.9719 20.5646 12.8145C20.5646 8.53374 17.0943 5.06348 12.8136 5.06348C8.53276 5.06348 5.0625 8.53374 5.0625 12.8145C5.0625 13.9719 5.31619 15.0701 5.77099 16.0565C7.41814 14.27 9.96068 13.1246 12.8136 13.1246C15.6664 13.1246 18.209 14.27 19.8561 16.0565Z"
        fill="#30313D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0555 30.0876C15.0691 30.5424 13.971 30.796 12.8135 30.796C8.53276 30.796 5.0625 27.3258 5.0625 23.045C5.0625 18.7642 8.53276 15.2939 12.8135 15.2939C13.971 15.2939 15.0691 15.5476 16.0555 16.0024C14.269 17.6496 13.1236 20.1921 13.1236 23.045C13.1236 25.8979 14.269 28.4404 16.0555 30.0876Z"
        fill="#30313D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0876 19.9455C30.5424 20.9318 30.796 22.03 30.796 23.1874C30.796 27.4682 27.3258 30.9385 23.045 30.9385C18.7642 30.9385 15.2939 27.4682 15.2939 23.1874C15.2939 22.03 15.5476 20.9318 16.0024 19.9455C17.6496 21.732 20.1921 22.8774 23.045 22.8774C25.8979 22.8774 28.4404 21.732 30.0876 19.9455Z"
        fill="#30313D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9445 5.77084C20.9309 5.31604 22.029 5.06235 23.1864 5.06235C27.4672 5.06235 30.9375 8.53261 30.9375 12.8134C30.9375 17.0942 27.4672 20.5645 23.1865 20.5645C22.029 20.5645 20.9309 20.3108 19.9445 19.856C21.731 18.2088 22.8764 15.6663 22.8764 12.8134C22.8764 9.96053 21.731 7.41799 19.9445 5.77084Z"
        fill="#30313D"
      />
    </svg>
  );
};

export default LogoLight;
