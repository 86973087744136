import React from "react";
import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import LogoLight from "../assets/images/Logo-Light";
import ReachLight from "../assets/images/Reach-Light.js";

import { Linkedin, Instagram, Tiktok, TwitterX } from "react-bootstrap-icons";

const Footer = () => {
  return (
    <>
      <Box id="footer">
        <Container sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
            <LogoLight style={{ width: "28px", height: "28px" }} />
            <ReachLight style={{ height: "18px" }} />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "16px", flexWrap: "wrap" }}>
            <a className="footer-link" href="/privacy">
              Privacy Policy
            </a>

            <a className="footer-link" href="/terms-conditions">
              Terms & Conditions
            </a>

            <a className="footer-link" href="/about">
              About
            </a>

            <a className="footer-link" href="/about/individuals">
              Individuals
            </a>

            <a className="footer-link" href="/about/companies">
              Companies
            </a>

            <a className="footer-link" href="/waitlist">
              Waitlist
            </a>

            <a className="footer-link" href="/contact">
              Contact
            </a>
          </Box>

          <Grid container sx={{ alignItems: "center" }} rowSpacing={4}>
            <Grid size={{ xs: 12, md: 6 }} sx={{ alignItems: "center", justifyContent: "center", textAlign: "center", gap: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
                <button className="btn-icon gray-300" onClick={() => window.open("https://www.linkedin.com/company/reachsocialus/")}>
                  <Linkedin style={{ height: "24px", width: "24px" }} />
                </button>
                <button className="btn-icon gray-300" onClick={() => window.open("https://www.instagram.com/reachsocial.us/")}>
                  <Instagram style={{ height: "24px", width: "24px" }} />
                </button>
                <button className="btn-icon gray-300">
                  <Tiktok style={{ height: "24px", width: "24px" }} />
                </button>
                <button className="btn-icon gray-300">
                  <TwitterX style={{ height: "24px", width: "24px" }} />
                </button>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} sx={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
              <p className="text" style={{ fontSize: "12px"}}>&copy; PartTime LLC. All rights reserved.</p>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
