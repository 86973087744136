import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Main from "./pages/Main";
import Footer from "./components/Footer";
import Waitlist from "./pages/Waitlist";
import NavbarWrapper from "./components/NavbarWrapper";

import "./assets/style/App.css";
import "./assets/style/Button.css";
import About from "./pages/About/About";
import Individuals from "./pages/About/Individuals";
import Companies from "./pages/About/Companies";
import Contact from "./pages/Contact";
import PrivacyPage from "./pages/Legal/PrivacyPage";
import TermsPage from "./pages/Legal/TermsPage";

function App() {
  return (
    <div className="app-container">
      <Router>
        <NavbarWrapper />
        <div className="content">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<About />} />
            <Route path="/about/individuals" element={<Individuals />} />
            <Route path="/about/companies" element={<Companies />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms-conditions" element={<TermsPage />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
