import React from "react";

const Reach = ({ style }) => {
  return (
    <>
      <svg style={style} viewBox="0 0 81 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M70.9739 11.4181V20.1588H67.7808C67.2285 20.1588 66.7808 19.7111 66.7808 19.1588V1C66.7808 0.447715 67.2285 0 67.7808 0H70.8558V7.7072H71.033C71.3742 6.81476 71.9255 6.11589 72.6867 5.61061C73.4479 5.09876 74.4027 4.84284 75.551 4.84284C76.601 4.84284 77.5164 5.07251 78.2973 5.53186C79.0847 5.98465 79.695 6.63758 80.1281 7.49065C80.5678 8.33717 80.7843 9.35101 80.7778 10.5322V20.1588H77.5846C77.0323 20.1588 76.5846 19.7111 76.5846 19.1588V11.2803C76.5911 10.3485 76.3549 9.62334 75.8759 9.10493C75.4034 8.58653 74.7406 8.32732 73.8875 8.32732C73.3166 8.32732 72.8113 8.44872 72.3717 8.69152C71.9386 8.93432 71.5974 9.28867 71.348 9.75458C71.1052 10.2139 70.9805 10.7684 70.9739 11.4181Z"
          fill="#30313D"
        />
        <path
          d="M57.3438 20.454C55.7951 20.454 54.463 20.1259 53.3475 19.4697C52.2385 18.807 51.3854 17.8883 50.7882 16.7136C50.1976 15.539 49.9023 14.1872 49.9023 12.6583C49.9023 11.1096 50.2009 9.75123 50.7981 8.58318C51.4018 7.40856 52.2581 6.49314 53.3671 5.83693C54.4761 5.17416 55.7951 4.84277 57.3241 4.84277C58.6431 4.84277 59.798 5.08229 60.7889 5.56132C61.7798 6.04036 62.5639 6.71298 63.1414 7.57917C63.7189 8.44537 64.0371 9.4625 64.0962 10.6306H61.0023C60.5065 10.6306 60.1044 10.2579 59.9182 9.79837C59.763 9.41538 59.5414 9.08578 59.2534 8.80957C58.7809 8.34366 58.1608 8.11071 57.393 8.11071C56.7433 8.11071 56.1757 8.28788 55.6901 8.64224C55.2111 8.99003 54.8371 9.49859 54.568 10.1679C54.299 10.8373 54.1644 11.6477 54.1644 12.5992C54.1644 13.5638 54.2957 14.3841 54.5582 15.06C54.8272 15.7359 55.2045 16.251 55.6901 16.6054C56.1757 16.9597 56.7433 17.1369 57.393 17.1369C57.872 17.1369 58.3018 17.0385 58.6825 16.8416C59.0696 16.6447 59.3879 16.3593 59.6372 15.9852C59.7479 15.8207 59.842 15.6421 59.9195 15.4493C60.1045 14.9894 60.5063 14.617 61.002 14.617H64.0962C64.0306 15.772 63.7156 16.7891 63.1512 17.6684C62.5935 18.5412 61.8224 19.2236 60.8381 19.7158C59.8538 20.208 58.689 20.454 57.3438 20.454Z"
          fill="#30313D"
        />
        <path
          d="M38.7446 20.4442C37.78 20.4442 36.9204 20.2769 36.1657 19.9422C35.4111 19.601 34.8139 19.099 34.3743 18.4362C33.9412 17.7669 33.7246 16.9335 33.7246 15.936C33.7246 15.0961 33.8788 14.3906 34.1872 13.8197C34.4957 13.2488 34.9156 12.7895 35.4472 12.4417C35.9787 12.0939 36.5824 11.8314 37.2583 11.6542C37.9408 11.4771 38.656 11.3524 39.4041 11.2802C40.2834 11.1883 40.9921 11.103 41.5302 11.0243C42.0683 10.939 42.4588 10.8143 42.7016 10.6502C42.9444 10.4862 43.0658 10.2434 43.0658 9.92185V9.86279C43.0658 9.23939 42.8689 8.75707 42.4752 8.41584C42.088 8.07461 41.5368 7.904 40.8215 7.904C40.0669 7.904 39.4665 8.07133 39.0202 8.406C38.574 8.73411 38.2787 9.14752 38.1343 9.64624L35.3917 9.42349C34.7899 9.37461 34.36 8.80092 34.6015 8.24752C34.8088 7.77234 35.0809 7.33957 35.4176 6.94921C35.9951 6.27331 36.7399 5.75491 37.652 5.39399C38.5707 5.02651 39.6338 4.84277 40.8412 4.84277C41.6812 4.84277 42.485 4.94121 43.2528 5.13807C44.0271 5.33493 44.7129 5.64007 45.31 6.05348C45.9137 6.4669 46.3895 6.99843 46.7373 7.64808C47.0851 8.29116 47.259 9.06221 47.259 9.96122V20.1587H44.2823C43.73 20.1587 43.2823 19.711 43.2823 19.1587V18.0622H43.1642C42.9214 18.5346 42.5966 18.9513 42.1897 19.3122C41.7829 19.6666 41.294 19.9455 40.7231 20.1489C40.1522 20.3458 39.4927 20.4442 38.7446 20.4442ZM39.9455 17.5503C40.5623 17.5503 41.107 17.4289 41.5795 17.1861C42.0519 16.9367 42.4227 16.6021 42.6917 16.1821C42.9608 15.7621 43.0953 15.2864 43.0953 14.7548V13.1504C42.9641 13.2357 42.7836 13.3145 42.5539 13.3866C42.3308 13.4523 42.0782 13.5146 41.796 13.5737C41.5138 13.6262 41.2317 13.6754 40.9495 13.7213C40.6673 13.7607 40.4114 13.7968 40.1817 13.8296C39.6896 13.9018 39.2598 14.0166 38.8923 14.1741C38.5248 14.3316 38.2393 14.5449 38.0359 14.8139C37.8325 15.0764 37.7308 15.4045 37.7308 15.7982C37.7308 16.3691 37.9375 16.8055 38.3509 17.1074C38.7709 17.4027 39.3024 17.5503 39.9455 17.5503Z"
          fill="#30313D"
        />
        <path
          d="M24.7578 20.454C23.2026 20.454 21.8639 20.1391 20.7418 19.5091C19.6262 18.8726 18.7666 17.9736 18.1629 16.8121C17.5592 15.644 17.2573 14.2627 17.2573 12.6681C17.2573 11.1129 17.5592 9.74795 18.1629 8.57334C18.7666 7.39872 19.6164 6.4833 20.7123 5.82709C21.8147 5.17088 23.1074 4.84277 24.5905 4.84277C25.5879 4.84277 26.5165 5.00355 27.3761 5.32509C28.2423 5.64007 28.9969 6.11582 29.64 6.75235C30.2897 7.38887 30.795 8.18945 31.1559 9.15408C31.5168 10.1121 31.6973 11.2343 31.6973 12.5204V12.6721C31.6973 13.2244 31.2495 13.6721 30.6973 13.6721H18.9307V11.0735H27.7501C27.7501 10.4698 27.6189 9.93497 27.3564 9.46906C27.0939 9.00315 26.7297 8.63896 26.2638 8.37647C25.8045 8.10742 25.2697 7.9729 24.6594 7.9729C24.0229 7.9729 23.4585 8.12055 22.9664 8.41584C22.4808 8.70458 22.1002 9.09502 21.8246 9.58718C21.5489 10.0728 21.4079 10.6142 21.4013 11.2113V13.6819C21.4013 14.43 21.5391 15.0764 21.8147 15.621C22.0969 16.1657 22.4939 16.5857 23.0057 16.881C23.5176 17.1763 24.1246 17.3239 24.8267 17.3239C25.2926 17.3239 25.7192 17.2583 26.1063 17.127C26.4935 16.9958 26.8249 16.7989 27.1005 16.5365C27.2025 16.4393 27.2955 16.334 27.3796 16.2207C27.6414 15.8675 28.0301 15.5916 28.4689 15.6206L31.6087 15.8278C31.4118 16.7596 31.0082 17.5733 30.398 18.2689C29.7942 18.9579 29.0134 19.496 28.0553 19.8831C27.1038 20.2637 26.0046 20.454 24.7578 20.454Z"
          fill="#30313D"
        />
        <path
          d="M1 20.1588C0.447716 20.1588 0 19.7111 0 19.1588V1C0 0.447715 0.447715 0 1 0H7.95328C9.47569 0 10.775 0.272327 11.8512 0.816983C12.9339 1.35508 13.7575 2.11956 14.3218 3.11044C14.8927 4.09476 15.1782 5.25297 15.1782 6.58508C15.1782 7.92375 14.8894 9.0754 14.312 10.04C13.7345 10.9981 12.8978 11.7331 11.802 12.2449C10.7126 12.7568 9.39367 13.0127 7.84501 13.0127H2.51985V9.58725H7.15598C7.96969 9.58725 8.64558 9.47569 9.18368 9.25258C9.72177 9.02947 10.1221 8.6948 10.3845 8.24858C10.6536 7.80235 10.7881 7.24786 10.7881 6.58508C10.7881 5.91575 10.6536 5.3514 10.3845 4.89206C10.1221 4.43271 9.71849 4.08491 9.17383 3.84868C8.63574 3.60588 7.95656 3.48448 7.1363 3.48448H4.26209V20.1588H1ZM10.8865 10.985L15.8967 20.1588H11.7912C11.4221 20.1588 11.0831 19.9556 10.9092 19.6301L6.28978 10.985H10.8865Z"
          fill="#30313D"
        />
      </svg>
    </>
  );
};

export default Reach;
